// Step 1: Import React
import * as React from 'react';
import Layout from '../components/Layout';
import { graphql } from 'gatsby';
import { Container } from "reactstrap";
import { BiLinkExternal } from "react-icons/bi";
import 'normalize.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'toastr/build/toastr.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; 


// Step 2: Define your component
const LatestAnnouncements = () => {

  return (
    <main>
      <Layout>
        <title>Latest Announcements</title>
        <Container className="siteStyl">
          <h2>Latest Announcements</h2>
          <h4 style={{color: 'grey'}}>15th Nov, 2021</h4>
          <p>As of Nov 2021, we're bringing out an upgraded version (2.0) of our tool at MagicAuthor.com with the below listed new features:</p>
          <p> 1. Editor with advanced features like RTL language support, inserting Tables, Special characters and symbols</p>
          <p> 2. Power to use various font styles</p>
          <p> 3. Canva integration, so one can design cover pages from within the platform</p>
          <p> 4. Ability to create 2 sided PDF with blank pages in between so that new chapters always start on the right side of the book</p>
          <p> 5. Generate print ready PDF files</p>
          <p> 6. Stripe payment gateway integration</p><br />
          <p>The earlier version (1.0), the one having the eBooks marketplace, is being moved to <a href="https://india.magicauthor.com" target="_blank" rel="noopener noreferrer">India <BiLinkExternal /></a> domain. </p>
          <p>All the data including the users and are retained as is. </p>
          <p>Because of this change, the URLs for the existing ebooks and blogs that are showcased currently at our website, change from .com to .in domain.</p>
          <p>If you wish to edit the existing eBooks/blogs then you can continue to do so at <a href="https://india.magicauthor.com" target="_blank" rel="noopener noreferrer">India <BiLinkExternal /></a> domain.</p>
          <p>Whereas for creating new eBooks, we recommend you to use MagicAuthor.com platform after signing up afresh. </p>
          <p>The existing users will get an email invite to join the new platform.</p>
          <br />
          <p>For any questions/clarifications, please write to us at support@magicauthor.com </p>
        </Container>
      </Layout>
    </main>
    )
}

// Step 3: Export your component
export default LatestAnnouncements

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["home", "common"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;